import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons/faQuoteLeft';

import testimonialHook from 'hooks/testimonials/testimonials.hook';

import {
  testimonial as testimonialClass,
  testimonial__wrapper,
  testimonial__icon,
  testimonial__comment,
  testimonial__author,
  testimonial__brand,
} from './Testimonials.scss';

export default (): JSX.Element => {
  const testimonials = testimonialHook();
  const testimonial = testimonials[Math.floor(Math.random() * testimonials.length)];
  const { quote, customer_name } = testimonial.node;

  return (
    <div className={testimonialClass}>
      <div className={testimonial__wrapper}>
        <span className={testimonial__icon}>
          <FontAwesomeIcon icon={faQuoteLeft} />
        </span>

        <p className={testimonial__comment}>&ldquo;{quote}&rdquo;</p>
        <p className={testimonial__author}>{customer_name}</p>
        <p className={testimonial__brand}>Rapid Formations Customer</p>
      </div>
    </div>
  );
};
