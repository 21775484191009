import React from 'react';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import renewalItemsHook from 'hooks/renewal-items/renewal-items.hook';

import {
  renewalItems,
  renewalItems__item,
  renewalItems__header,
  renewalItems__title,
  renewalItems__price,
  renewalItems__body,
  renewalItems__content,
  renewalItems__buttons,
} from './RenewalItems.scss';

export default (): JSX.Element => {
  const items = renewalItemsHook();

  return (
    <div className={renewalItems}>
      {items.map(({ node }) => (
        <div key={`renewal-item-${node.directusId}`} className={renewalItems__item}>
          <div className={renewalItems__header}>
            <h4 className={renewalItems__title}>
              <Markdown source={node.title} />
            </h4>
            <div className={renewalItems__price}>
              <PriceWithoutVat price={node.price.value} />
            </div>
          </div>
          <div className={renewalItems__body}>
            <Markdown className={renewalItems__content} container source={node.body} />
            <div className={renewalItems__buttons}>
              <Button role="primary" to={node.button_link} ariaLabel={`Order our ${node.title} service now.`}>
                {node.button_text}
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
