import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusRenewalItem, RenewalItems } from './renewal-items.interface';

export default (): RenewalItems[] => {
  const { allDirectusRenewalItem } = useStaticQuery<AllDirectusRenewalItem>(graphql`
    query {
      allDirectusRenewalItem(sort: { order: ASC, fields: sort }) {
        edges {
          node {
            directusId
            slug
            sort
            title
            body
            button_link
            button_text
            price {
              value
              name
            }
          }
        }
      }
    }
  `);

  return allDirectusRenewalItem.edges;
};
