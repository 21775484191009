import React from 'react';

import { Props } from './PriceWithoutVat.interface';

import { postText as postTextClass } from './PriceWithoutVat.scss';

export default ({ price, postText, currency = true }: Props): JSX.Element => (
  <>
    {currency && '\u00A3'}
    {price}
    {postText && (
      <>
        &nbsp;<small className={postTextClass}>{postText}</small>
      </>
    )}
  </>
);
