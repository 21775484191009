import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusTestimonial, TestimonialEdges } from './testimonials.interface';

export default (): TestimonialEdges[] => {
  const { allDirectusTestimonial } = useStaticQuery<AllDirectusTestimonial>(graphql`
    query {
      allDirectusTestimonial {
        edges {
          node {
            directusId
            quote
            customer_name
          }
        }
      }
    }
  `);

  return allDirectusTestimonial.edges;
};
